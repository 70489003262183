import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from '../_alert/alert.service';

import { Config } from '../data-model';

export interface SelectionLanguage{
  code: string;
  name: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  /*template: `
    <p>
      login works!
    </p>
  `,*/
  styles: []
})
export class LoginComponent implements OnInit {

//	@Input('user') user: User;
	actionArg: string;
	appLogin: string;
	appIsGuest: boolean;
	config: Config;
	
	hide: boolean = true;
	hide2: boolean = true;
	
	//Error 
	displayErrorDiv = false;
	error = {message : ''};
	
	heroForm: FormGroup;
	modifyForm: FormGroup;
	
	languages: SelectionLanguage[]; // type this.languages = [{code: 'steak-0', name: 'Steak2'}}];
	public selectedLanguage : string;
  
	headers: HttpHeaders;
  
	displayLoginDiv = false;
	displayAlreadyLoginDiv = false;
	displayModifyDiv = false;
	
	constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private route: ActivatedRoute, private alertService: AlertService, public snackBar: MatSnackBar) {
		this.config = new Config();
		this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		this.headers.set('Access-Control-Allow-Origin' , '*');
		this.headers.set('Access-Control-Allow-Methods', 'GET,POST,PUT,HEAD,DELETE,OPTIONS');
		this.headers.set('Access-Control-Allow-Headers', 'origin, content-type, accept');
		this.headers.append('Access-Control-Allow-Credentials', 'true');

		this.createForm();
		
		this.appLogin = localStorage.getItem('login');
		this.appIsGuest = (localStorage.getItem('isGuest')=="1");
		//alert('valeur isGuest: '+localStorage.getItem('isGuest')+' '+(localStorage.getItem('isGuest')=="1"));
	}
	
	setAlert(type: string, message: string){
		this.alertService.alertType(type, message, false);
		//Success, Error, Info, Warning
		this.openSnackBar('Message archived');
	}

   createForm() {
		this.heroForm = this.fb.group({
		  login: '',
		  pasmot: ['', Validators.required ],
		  email: ['', Validators.required ],
		  create: false
		});
		
		let allowCookieAnalytic = false;
		if(localStorage.getItem('allowCookieAnalytic') == '1'){
			allowCookieAnalytic = true;
		}
		//console.log('allowCookieAnalytic:'+localStorage.getItem('allowCookieAnalytic'));
		
		this.modifyForm = this.fb.group({
			dises: '',
		  email: ['', Validators.required ],
		  pasmot: ['' ],
		  pasmot2: ['', Validators.required ],
		  login: ['', Validators.required ],
		  allowNewsMedoom: false,
		  allowNewsPartner: false,
		  language: ['', Validators.required ],
		  allowCookieAnalytic: allowCookieAnalytic,
		  create: false
		});
		
	}
	
  ngOnInit() {
	this.route.params.subscribe(params => {
		this.actionArg = params['action']; // (+) converts string 'id' to a number
	});
	
	//console.log('formClik:'+(this.actionArg) );
	if(this.actionArg=='logout'){
		this.logout();
	}
	if(this.actionArg=='guest'){
		this.clickedGuest();
	}
	
	this.refreshDiv();
	
	//Get list of languages available
	this.getLanguage();
	
	//Get user informations
	if(this.actionArg!='guest'){
		this.getUserInfos();
	}
  }
  
  openSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 1000 });
  }
  
  refreshDiv(){
	this.displayLoginDiv = false;
	this.displayAlreadyLoginDiv = false;
	this.displayModifyDiv = false;
	
	if( localStorage.getItem('login') != '' && localStorage.getItem('login') != null ){
		if(this.actionArg=='modify'){
			this.displayModifyDiv = true;
		}else{
			this.displayAlreadyLoginDiv = true;
		}
	}else{
		this.displayModifyDiv = true;
	}
  }
  
  logout(){
	localStorage.setItem('dises', '');
	localStorage.setItem('login', '');
	localStorage.setItem('isGuest', '');
	//localStorage.setItem('allowCookieAnalytic', '');
	
	this.appLogin = '';
	this.appIsGuest = false;
	//this.login = '';
	
	this.createForm();
	
	this.refreshDiv();
  }
  
  clickedLogout(){
	this.logout();
  }
  
  clickedModify(){
	this.actionArg='modify';
	this.refreshDiv();
	
	/* this.modifyForm.patchValue({email:this.modifyForm.value.email});
	this.modifyForm.patchValue({login:"test_login"});
	
	alert(this.modifyForm.value.email);
	//this.getUserInfos(); 
	this.selectedLanguage = 'fr'; */
	
  }
		
	clickedGuest() {
		let post = [];
		post.push( { dises : localStorage.getItem('dises') } );
		post.push( { guest : 'guest' } );
		post.push( { allowCookieAnalytic : (localStorage.getItem('allowCookieAnalytic')=='1')?'1':'0' } );
		
		//If already connected as guest
		if(this.appIsGuest == true){
			this.router.navigate(['/histo']);
		}else{
			this.http.post(this.config.httpAddress+"setLogin.php", post, {headers: this.headers}).subscribe((data) => this.extractDataLogin(data));
			this.alertService.setProgressBar(1);
		}
	}
	
	clickedSave() {
	  //console.log('formClik'+(this.modifyForm.value) );
	  
	  this.modifyForm.value.dises = localStorage.getItem('dises');

	  /*if(this.user.dises != ""){
		formData.append('dises', this.user.dises);
		}*/
	   
		this.http.post(this.config.httpAddress+"setLogin.php", this.modifyForm.value, {headers: this.headers}).subscribe((data) => this.extractDataLogin(data));
		this.alertService.setProgressBar(1);
	}
	
	cancel(){
		window.history.back();
	}
	
	clicked() {
	  //console.log('formClik'+(this.heroForm.value) );

	  /*if(this.user.dises != ""){
		formData.append('dises', this.user.dises);
		}*/
	   
		this.http.post(this.config.httpAddress+"setLogin.php", this.heroForm.value, {headers: this.headers}).subscribe((data) => this.extractDataLogin(data));
		this.alertService.setProgressBar(1);

	  //selectTab(tabs[0]);
	  /*
	  this.http.post(config.httpAddress+"login.php", this.heroForm.value, {headers: this.headers})
					.then(function (response) {
					console.log("retour:"+ response);
            return response;
        });
				   //.subscribe(this.extractData);
	   */
				   
		//Observable<Response> ob = this.http.post(https://backtest.djean.fr/login.php, this.heroForm.value, {headers: this.headers}); 
		
	}
	extractDataLogin(res) {
		//alert('toto');
		this.alertService.setProgressBar(-1);
		
		//console.log(res.login);
		if(res.result == "ok"){
			//Return from modify
			if (typeof res.modify !== 'undefined') {
				this.router.navigate(['/histo']);
				
				this.setAlert('Success', 'loginAccountModify');
			}else{
				let id=0;
				//this.user.dises = res.dises;
				localStorage.setItem('dises', res.dises);
				localStorage.setItem('login', res.login);
				localStorage.setItem('isGuest', res.isInvitMode.toString());
				localStorage.setItem('allowCookieAnalytic', res.allowCookieAnalytic.toString());
				this.appLogin = res.login;
				this.appIsGuest = (res.isInvitMode==1);
				
				
				//console.log('dises:'+localStorage.getItem('dises'));
				//console.log('log:'+this.login);
				
				//this.router.navigateByUrl('/send-media');
				//this.router.navigate(['/send-media', id]);
				this.router.navigate(['/histo']);
			}
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
	
	
	getLanguage() {
		let post = [];
		post.push( { dises : localStorage.getItem('dises') } );
		this.http.post(this.config.httpAddress+"getLanguage.php", post, {headers: this.headers}).subscribe((data) => this.extractDataLanguage(data));
		this.alertService.setProgressBar(1);
	}
	
	extractDataLanguage(res) {
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		//console.log(res.login);
		//console.log(res.dises);
		if(res.result == "ok"){
			//let id=0;
			//this.user.dises = res.dises;
			//localStorage.setItem('dises', res.dises);
			
			//var jsonData = JSON.parse(res);
			var count = Object.keys(res).length;
			//console.log(count);
			
			/* this.languages = [
				{code: 'steak-0', name: 'Steak2'},
				{code: 'pizza-1', name: 'Pizza'},
				{code: 'tacos-2', name: 'Tacos'}
			]; */
			this.languages = [];
		
			// for each event returned
			for (var i = 0; i < Object.keys(res).length; i++) {
				//console.log(res[i]); 
				if(typeof res[i] !== "undefined"){
					//console.log(res[i]['code']);
					//console.log(res[i]['name']);
					
					this.languages.push({code: res[i]['code'], name: res[i]['name']});
					
					//this.selectedLanguage = 'fr';
				}
			}
						
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
				//Action special here:
				if(res.error == "noLogin"){
					if(localStorage.getItem('dises') == '' && localStorage.getItem('login') == '' ){
						//Nothing to do: do not go to "logout" route
						
						actionDone = true; //And do not the standard actions
					}
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
	
	
	getUserInfos() {
		let post = [];
		post.push( { dises : localStorage.getItem('dises') } );
		if(this.appIsGuest == false){
			this.http.post(this.config.httpAddress+"getUser.php", post, {headers: this.headers}).subscribe((data) => this.extractDataUser(data));
			this.alertService.setProgressBar(1);
		}
	}
	
	extractDataUser(res) {
		this.alertService.setProgressBar(-1);
		//console.log(res);
		if(res.result == "ok"){
			
			var count = Object.keys(res).length;
			//console.log(count);
		
			// for each event returned
			for (var i = 0; i < Object.keys(res).length; i++) {
				//console.log(res[i]); 
				if(typeof res[i] !== "undefined"){
					//console.log(res[i]['code']);
	/* "email_user": '.json_encode(htmlentities($row['email_user'])).',
	 "login_user": '.json_encode(htmlentities($row['login_user'])).',
	"isInvitMode_user": '.json_encode(htmlentities($row['isInvitMode_user'])).',
	"allowNewsMedoom": '.json_encode(htmlentities($row['allowNewsMedoom'])).',
	"allowNewsPartner": '.json_encode(htmlentities($row['allowNewsPartner'])).',
	"language": '.json_encode(htmlentities($row['language'])).' */
					
					/* this.modifyForm = this.fb.group({
		  email: ['', Validators.required ],
		  pasmot: ['' ],
		  pasmot2: ['', Validators.required ],
		  login: ['', Validators.required ],
		  allowNewsMedoom: false,
		  allowNewsPartner: false,
		  language: ['', Validators.required ] */
		  
					//alert(this.modifyForm.value.email);
					
					this.modifyForm.patchValue({email:res[i]['email_user']});
					this.modifyForm.patchValue({login:res[i]['login_user']});
					this.modifyForm.patchValue({allowNewsMedoom:res[i]['allowNewsMedoom']=="1"});
					this.modifyForm.patchValue({allowNewsPartner:res[i]['allowNewsPartner']=="1"});
					this.modifyForm.patchValue({language:res[i]['language']});
					this.modifyForm.patchValue({allowCookieAnalytic:res[i]['allowCookieAnalytic']=="1"});
					
					
					//this.selectedLanguage = 'fr';
				}
			}
			
			
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
				//Action special here:
				if(res.error == "noLogin"){
					if(localStorage.getItem('dises') == '' && localStorage.getItem('login') == '' ){
						//Nothing to do: do not go to "logout" route
						
						actionDone = true; //And do not the standard actions
					}
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
}
