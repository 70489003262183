import { Component, OnInit, Input, AfterViewInit, ViewChild  } from '@angular/core';
import { Router, NavigationStart  } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertService } from './_alert/alert.service';
import { AlertComponent } from './_alert/alert.component';

import { Config } from './data-model';

//import { User }        from './data-model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  //user: User;
  title = 'app';
  displayCookieDiv = true;
  results = '';
  pageTitle : string = "index";
	config: Config;
  
  menuIsLogin = false;
  displayProgressBar = true;
  displayMenuIcon = true;
  displayBackIcon = false;
  queryInProgress = 0;
  
	post = [];
	headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  @ViewChild(AlertComponent, {static: false}) alertTraduction: AlertComponent;
  
  constructor(private router: Router, private http: HttpClient, private alertService: AlertService){
		this.config = new Config();
		
		// clear alert messages on route change unless 'keepAfterRouteChange' flag is true
		router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.refreshMenu();
				this.refreshMenuTitle(event);
			}
		});
  }

  ngOnInit(): void {
	//this.http.get<UserResponse>('https://backtest.djean.fr/seeschweiler.php').subscribe(data => {
	/*
	this.http.get('https://backtest.djean.fr/seeschweiler.php').subscribe(data => {
      console.log("User Login: " + data.login);
      console.log("Bio: " + data.bio);
      console.log("Company: " + data.company);
    });
	*/
	if(localStorage.getItem('allowCookieAnalytic') == '1'){
		this.displayCookieDiv = false;
	}
	//console.log("allowCookieAnalytic: " + localStorage.getItem('allowCookieAnalytic'));
	
	this.refreshMenu();
  }
  
  componentAdded($event){
	  //alert("componentAdded:"+$event);
	  //console.log($event);
  }
  
  componentRemoved($event){
	  //alert("componentRemoved:"+$event);
  }
  
	getTrad(type: string, message: string){
		return "";
	}
  
  refreshMenu(){
	if( localStorage.getItem('login') != '' && localStorage.getItem('login') != null ){
		this.menuIsLogin = true;
	}else{
		this.menuIsLogin = false;
	}
  }
   
  refreshMenuTitle(eventA:NavigationStart){
	
	let urls = eventA.url.split("/");
	
	let menuName = urls[1];
	if( menuName == ""){
		menuName = "index";
	}
	
	this.pageTitle = this.alertTraduction.getTraduction('menu_'+ menuName);
	
	//Display Menu or Back Arrow, depending on the page
	if( menuName == "send-media" || menuName == "manage-display" || menuName == "manage-form" ){ 
		this.displayMenuIcon = false;
		this.displayBackIcon = true;
	}else{
		this.displayMenuIcon = true;
		this.displayBackIcon = false;
	}
	
	// alert(this.pageTitle);
	// console.log("urlA"+urls[1]);
	// console.log("urlB"+eventA.url);
  }
  	
	clickedCookie(choice) {
		if(choice == 'ok'){
			localStorage.setItem('allowCookieAnalytic', "1");
		}else{
			localStorage.setItem('allowCookieAnalytic', "0");
		}
		
		this.displayCookieDiv = false;
	}
	
	setAlert(type: string, message: string){
		this.alertService.alertType(type, message, false);
		//Success, Error, Info, Warning
	}
	
	clickedBack(){
		window.history.back();
	}
	
	getActions(){
		
		this.post = [];
		this.post.push( { dises : localStorage.getItem('dises') } );
		
		this.http.post(this.config.httpAddress+"getActions.php", this.post, {headers: this.headers}).subscribe((data) => this.extractDataActions(data));
		//this.alertService.setProgressBar(1);
		
		setTimeout(()=>{
			this.getActions();
		},10000); /* rappel après 2 secondes = 2000 millisecondes */
	}
	
	//get actions from server for this user/manager
	extractDataActions(res) {
		//console.log(res);
		//console.log(res.result);
		
		if(res.result == "ok"){
			let numberDisplays;
			let item;
			
			// for each event returned
			for (var i = 0; i < Object.keys(res).length; i++) {
				//console.log(res[i]);
				if(typeof res[i] !== "undefined"){
					//console.log(res[i]['name_event']);
					if(res[i]['new_mediaToValidate']!== "undefined" && res[i]['new_mediaToValidate']!= ""){
						this.alertService.alertType("Info", "A new media is waiting to be validated.", true);
					}
				}
			}
			
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
}
