import { Component, OnInit, Input, ElementRef, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from '../_alert/alert.service';

import { Config } from '../data-model';

import { Ng2ImgMaxService } from 'ng2-img-max';
//import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-send-media',
  templateUrl: './send-media.component.html',
  /*template: `
    <p>
      send-media works!
    </p>
  `,*/
  styles: []
})
export class SendMediaComponent implements OnInit {
	
	//Error 
	displayErrorDiv = false;
	error = {message : ''};
	
	dises: string;
	idParty: number;
	heroForm: FormGroup;
	headers: HttpHeaders;
	config: Config;
	post = [];
	
	histoActions = [];
	
	eventName: string = "";
	eventCode: string = "";
	//@ViewChild('commentaryToShare') commentaryToShare : ElementRef;
	
	uploadedImage: File=null;
	// uploadedImageBlob: Blob;
	
	constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private elem: ElementRef, private route: ActivatedRoute, private ng2ImgMax: Ng2ImgMaxService
	//, private fbk: FacebookService
	, public dialog: MatDialog, private alertService: AlertService) {
		this.config = new Config();
		
		this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		
		/*
		let initParams: InitParams = {
		  appId: '558185017913884',
		  xfbml: true,
		  version: 'v2.12'
		};
	 
		fbk.init(initParams);
		*/
		
		this.createForm();
	}
	
	setAlert(type: string, message: string){
		this.alertService.alertType(type, message, false);
		//Success, Error, Info, Warning
	}

   createForm() {
    this.heroForm = this.fb.group({
      fileInput: ['', Validators.required ],
      subtitle: ['', Validators.required ],
	  resizeImageBeforeSend: [''],
	  allowAuthorizePublication: ['']
    });
	}

  ngOnInit() {
	this.route.params.subscribe(params => {
		this.idParty = +params['id']; // (+) converts string 'id' to a number
	});
	
	this.dises = localStorage.getItem('dises');
	
	this.getActions();
	
	this.eventName = localStorage.getItem('eventName');
	this.eventCode = localStorage.getItem('eventCode');
	
  }
	
	/*
	openDialog(): void {
		let dialogRef = this.dialog.open(SendMediaComponentDialog, {
		  width: '250px',
		  //data: { name: this.name, animal: this.animal }
		  data : { }
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		  //this.animal = result;
		});
	}
	*/
  
	onImageChange() {
		//resizeImage()
	}
	
	resizeImageTooLong() {
		let image = this.elem.nativeElement.querySelector('#fileInput3').files[0];
		this.uploadedImage = image;
	}
	
	resizeImage() {
		if( this.heroForm.value.resizeImageBeforeSend != true ){
			this.resizeImageTooLong(); //Because resize image is too long in Smartphone
		}else{
	let image = this.elem.nativeElement.querySelector('#fileInput3').files[0];
	this.uploadedImage = null;
	
	this.ng2ImgMax.resizeImage(image, 1280, 1280).subscribe(
		result => {
		  this.uploadedImage = result;
		  // this.elem.nativeElement.querySelector('#fileInput').files[0] = this.uploadedImage;
		  
		  // this.uploadedImageBlob = result;
		  console.log('Resize OK');
		},
		error => {
		  console.log('! Oh no!', error);
		}
		);
		}
/* old
		this.ng2ImgMax.resizeImage(image, 1200, 800).subscribe(
		result => {
		//  this.uploadedImage = new File([result], result.name);
		  this.elem.nativeElement.querySelector('#fileInput').files[0] = this.uploadedImage;
		  
		  this.uploadedImageBlob = result;
		  console.log('Resize OK');
		},
		error => {
		  console.log('! Oh no!', error);
		}
		); */
	}
	
	clickedOnShare(){
		
	}
	
	clickedShare(id_share, filename_share, action = 'share'){
		
		this.post = [];
		this.post.push( { dises : localStorage.getItem('dises') } );
		this.post.push( { id_share : id_share } );
		this.post.push( { e : this.idParty } );
		this.post.push( { f : filename_share } );
		
		this.http.post(this.config.httpAddress+"setMedia.php", this.post, {headers: this.headers}).subscribe((data) => this.extractDataShare(data));
		this.alertService.setProgressBar(1);
		
		//TODO
		//Open windows
		//https://backtest.djean.fr/share/index.php?id=action.id_share&e=idParty&f=action.filename_share'
	}
	
	clicked(id_share, action = 'share'){
		//TODO
		
		this.post = [];
		this.post.push( { dises : localStorage.getItem('dises') } );
		this.post.push( { id_event : this.idParty } );
		
		/*this.http.post('https://www.facebook.com/v2.12/dialog/oauth?client_id={"603387366670900"}&redirect_uri={"https://a.meddom.com/login"}&state={"{st=state123abc,ds=123456789}"}', this.post, {headers: this.headers}).subscribe((data) => this.extractDataActions(data));
		*/
  
	/*
		 this.fbk.login()
      .then((response: LoginResponse) => console.log(response))
      .catch((error: any) => console.error(error));
	  
	  this.fbk.ShareDialogParams() href, hashtag ,quote ,mobile_iframe ,action_type,action_properties
	  .then((response: LoginResponse) => console.log(response))
      .catch((error: any) => console.error(error));
	  
	  */
	  
		//this.openDialog();
	}
	
  clickedSend() {
	  //console.log('formClik'+(this.heroForm.value) );
		this.alertService.setProgressBar(1);
		
		let legalExtensionsVideo = ["mp4", "mov", "m4v", "avi"];
		let legalExtensionsImage = ["jpg", "jpeg", "png", "gif", "jfif"]; // If modify, add too in setMedia.php File and Error Message (+ traductions) !
		let legalExtensions = legalExtensionsImage.slice().concat(legalExtensionsVideo);
		
		let fichier = this.elem.nativeElement.querySelector('#fileInput3').value;
		
		if( this.heroForm.value.allowAuthorizePublication == true){ //Check if this case is check
		if( legalExtensions.indexOf(fichier.split('.').pop()) >= 0 ){ //Check if extension if authorizate
			if(this.uploadedImage == null){
				this.resizeImage();
			}
		   
		   this.sendWhenResizeImageReady();
	   }else{
		   alert('test');
			this.alertService.setProgressBar(-1);
			this.setAlert("Error", "mediaExtensionKo");
		}
	   }else{
			this.alertService.setProgressBar(-1);
			this.setAlert("Error", "SendMediaAuthorizePublicationMustActive");
		}
	}
	
	sendWhenResizeImageReady() {
		let formData = new FormData();
	   let fichier = this.elem.nativeElement.querySelector('#fileInput3').value;
	    
		if( !(fichier == null || fichier == "") ){
			if(this.uploadedImage == null){
					setTimeout(()=>{
				this.sendWhenResizeImageReady();
				}, 500); /* rappel après 2 secondes = 2000 millisecondes */
			}else{
				formData.append('fileInput', this.uploadedImage, this.uploadedImage.name);
				formData.append('subtitle', this.heroForm.value.subtitle);
				formData.append('dises', localStorage.getItem('dises'));
				formData.append('id_event', this.idParty.toString());

				//this.http.post(this.config.httpAddress+"sendMedia.php", this.heroForm.value, {headers: this.headers}).subscribe((data) => this.extractData(data));
				this.http.post(this.config.httpAddress+"setMedia.php", formData, { withCredentials: false }).subscribe((data) => this.extractData(data));
				//this.alertService.setProgressBar(1);

				this.uploadedImage = null;
			}
		}else{
		   this.alertService.setProgressBar(-1);
		   this.setAlert("Error", "sendMediaNoMediaFound");
	   }
	}
	
	extractData(res) { 
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		if(res.result == "ok"){
			let id=0;
			
			//Remove datas in the form
			//this.heroForm.patchValue({subtitle:null});
			this.heroForm.reset();
			for( let i in this.heroForm.controls ) {
				this.heroForm.controls[i].setErrors(null);
			}
	
			this.elem.nativeElement.querySelector('#fileInput3').value = '';
			this.elem.nativeElement.querySelector('#fileInput2').value = '';
			this.elem.nativeElement.querySelector('#fileInput').value = '';
			this.uploadedImage = null;
			//Retrieve actions from this event
			this.getActions();
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
	
	getActions() {
		this.post = [];
		this.post.push( { dises : localStorage.getItem('dises') } );
		this.post.push( { id_event : this.idParty } );
		this.post.push( { userMode : 'yes' } );
		
		this.http.post(this.config.httpAddress+"getMediaList.php", this.post, {headers: this.headers}).subscribe((data) => this.extractDataActions(data));
		this.alertService.setProgressBar(1);
		
	}
	
	clickVideo(id) {
		if( this.elem.nativeElement.querySelector('#vid-'+id).paused || this.elem.nativeElement.querySelector('#vid-'+id).ended ){
			this.elem.nativeElement.querySelector('#vid-'+id).play();
		}else{
			this.elem.nativeElement.querySelector('#vid-'+id).pause();
		}
	}
	
	clickVideoReplay(id) {
		this.elem.nativeElement.querySelector('#vid-'+id).load();
		this.elem.nativeElement.querySelector('#vid-'+id).play();
	}
	
	extractDataShare(res) {
			this.alertService.setProgressBar(-1);
	}
	
	extractDataActions(res) {
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		//console.log(res.login);
		//console.log(res.dises);
		if(res.result == "ok"){
			//let id=0;
			//this.user.dises = res.dises;
			//localStorage.setItem('dises', res.dises);
			
			//var jsonData = JSON.parse(res);
			//console.log(res.length);
			var count = Object.keys(res).length;
			//console.log(count);
			
			// for each event returned
			for (var i = 0; i < Object.keys(res).length; i++) {
				//console.log(res[i]);
				if(typeof res[i] !== "undefined"){
					//console.log(res[i]['name_event']);
					
					// verify if media already exists on screen
					var alreadyExist=0;
					for (var j = 0; j < Object.keys(this.histoActions).length; j++) {
						if(typeof this.histoActions[j] !== "undefined"){
							if( this.histoActions[j]['id_share'] == res[i]['id_share']){
								alreadyExist++;
							}
						}
					}
					if( alreadyExist == 0 ){
						this.histoActions.unshift( {
							id_share: res[i]['id_share'],
							type_share: res[i]['type_share'],
							comment_share: res[i]['comment_share'],
							like_share: res[i]['like_share'],
							recurrent_share: res[i]['recurrent_share'],
							filename_share: res[i]['filename_share'],
							status_share: res[i]['status_share'],
							password_share: res[i]['password_share']
						} );
					}else{
						//console.log(res[i]['name_event']+" already Exist");
					}
					
				}
			}
			/*for (var i = 0; i < jsonData.counters.length; i++) {
				var counter = jsonData.counters[i];
				console.log(counter.counter_name);
			}*/
			
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
}
/*
@Component({
  selector: 'app-send-media-dialog',
  templateUrl: 'app-send-media-dialog.html',
})
export class SendMediaComponentDialog {

  constructor(
    public dialogRef: MatDialogRef<SendMediaComponentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
*/