import { isDevMode } from '@angular/core';

export class User {
  login = '';
  admin = 0;
  isInvitMode = 0;
  dises = ''; //session
}

export class Config {
  //public httpAddress: string = 'https://backtest.djean.fr/back/';
  public httpAddress: string = 'https://test.b.diapowall.com/';
  public httpZipAddress: string = 'https://test.b.diapowall.com/zip/';
  
  
  //constructor(public httpAddress: string) {
  constructor() {
	  if( !isDevMode() ){
		this.httpAddress = 'https://b.diapowall.com/';
		this.httpZipAddress = 'https://b.diapowall.com/zip/';
	  }
  }
}

export class Hero {
  id = 0;
  name = '';
  addresses: Address[];
}


export class Address {
  street = '';
  city   = '';
  state  = '';
  zip    = '';
}

export const heroes: Hero[] = [
  {
    id: 1,
    name: 'Whirlwind',
    addresses: [
      {street: '123 Main',  city: 'Anywhere', state: 'CA',  zip: '94801'},
      {street: '456 Maple', city: 'Somewhere', state: 'VA', zip: '23226'},
    ]
  },
  {
    id: 2,
    name: 'Bombastic',
    addresses: [
      {street: '789 Elm',  city: 'Smallville', state: 'OH',  zip: '04501'},
    ]
  },
  {
    id: 3,
    name: 'Magneta',
    addresses: [ ]
  },
];

export const states = ['CA', 'MD', 'OH', 'VA'];
