import { Injectable, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute  } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

import { Alert, AlertType } from '../_alert/alert';

@Injectable()
export class AlertService {
    private subject = new Subject<Alert>();
    private subjectProgressBar = new Subject<number>();
    private keepAfterRouteChange = false;
	
	//@ViewChild('allCommentary') allCommentary : ElementRef;
	//@ViewChildren('allCommentary') allCommentaryChild : any;
	
    constructor(private router: Router, public route : ActivatedRoute) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    getProgressBar(): Observable<any> {
        return this.subjectProgressBar.asObservable();
    }
	
    setProgressBar(number: number) {
        this.subjectProgressBar.next(number);
    }

    success(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Success, message, keepAfterRouteChange);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Error, message, keepAfterRouteChange);
    }

    info(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Info, message, keepAfterRouteChange);
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Warning, message, keepAfterRouteChange);
    }

    alert(type: AlertType, message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert>{ type: type, message: message });
    }

    alertType(typeS: string, message: string, keepAfterRouteChange = false) {
		let type: AlertType;
		
		switch(typeS){
            case 'Success':
                 type = AlertType.Success; break;
            case 'Error':
                 type = AlertType.Error; break;
            case 'Info':
                 type = AlertType.Info; break;
            case 'Warning':
                 type = AlertType.Warning; break;
        }
		
        this.alert(type, message, keepAfterRouteChange);
    }
	
	//Actions for return that not be 
	returnKo(res){
		if(typeof res.error !== "undefined"){
			if(res.error == "noLogin"){
				//this.openSnackBar('noLogin!');
				this.router.navigate(['/login', 'logout']); //Allow, because guest is not login
			}
			this.alertType('Error', res.error, false);
		}else{
			let componentName:string = "this component";
			if(this.route.snapshot != null){
				if(this.route.snapshot.routeConfig != null){
					if(this.route.snapshot.routeConfig.component != null){
						componentName = this.route.snapshot.routeConfig.component.name;
					}
				}
			}
			this.alertType('Error', 'Error. Return is not supported in '+componentName+'. Please try again.', false);
		}
	}

    clear() {
        // clear alerts
        this.subject.next();
    }
}