import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatInputModule,
		MatToolbarModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatListModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatDialogModule
		} from '@angular/material';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HistoComponent } from './histo/histo.component';
import { ManageFormComponent } from './manage-form/manage-form.component';
import { ManageDisplayComponent } from './manage-display/manage-display.component';
import { SendMediaComponent } from './send-media/send-media.component';
//import { SendMediaComponentDialog } from './send-media/send-media.component';
import { TabComponent } from './tab/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { PartyFormComponent } from './party-form/party-form.component';
import { IndexComponent } from './index/index.component';

import { Ng2ImgMaxModule } from 'ng2-img-max';
//import { FacebookModule } from 'ngx-facebook';

import { FormService }         from './form.service'; //  <-- #1 import service

// import alert service and component
import { AlertComponent } from './_alert/alert.component';
import { AlertService } from './_alert/alert.service';

import { ShareModule } from '@ngx-share/core';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { ClipboardModule } from 'ngx-clipboard';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const appRoutes: Routes = [
  { path: 'index', component: IndexComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:action', component: LoginComponent },
  { path: 'histo', component: HistoComponent },
  { path: 'manage-form/:id', component: ManageFormComponent },
  { path: 'manage-display/:id', component: ManageDisplayComponent },
  { path: 'send-media/:id', component: SendMediaComponent },
  { path: 'party-form', component: PartyFormComponent },
  { path: 'party-form/:id', component: PartyFormComponent },
  { path: '',
    redirectTo: '/index',
    pathMatch: 'full'
  },
  { path: '**', component: IndexComponent }
];

@NgModule({
  declarations: [
    AppComponent,
	AlertComponent,
    LoginComponent,
    HistoComponent,
    ManageFormComponent,
    ManageDisplayComponent,
    SendMediaComponent,
	//SendMediaComponentDialog,
    TabComponent,
    TabsComponent,
    PartyFormComponent,
    IndexComponent
  ],
  imports: [
	HttpClientModule,
    BrowserModule,
	BrowserAnimationsModule,
	FormsModule,
    ReactiveFormsModule,
	MatInputModule,
	MatToolbarModule,
	MatMenuModule,
	MatIconModule,
	MatButtonModule,
	MatListModule,
	MatSlideToggleModule,
	MatSelectModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatProgressSpinnerModule,
	MatSnackBarModule,
	MatTooltipModule,
	MatDialogModule,
	Ng2ImgMaxModule,
	AngularFontAwesomeModule,
	ShareModule,
	ShareButtonsModule,
	ClipboardModule,
	MatProgressBarModule,
	//ShareModule.forRoot(),
	//ShareButtonsModule.forRoot(),
	//FacebookModule.forRoot(),
	 RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
	ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [FormService, AlertService],
  bootstrap: [AppComponent],
  //entryComponents: [SendMediaComponentDialog]
})
export class AppModule { }
