import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {Observable} from 'rxjs/Rx';
import { AlertService } from '../_alert/alert.service';

import { Config } from '../data-model';

@Component({
  selector: 'app-manage-display',
  templateUrl: './manage-display.component.html',
  /*template: `
    <p>
      histo works!
    </p>
  `,*/
  styleUrls: ['../app.component.css']
})
export class ManageDisplayComponent implements OnInit {

	idParty: number;
	displayGpsDiv = false;
	displayHashtagDiv = false;
	listDisplays = [];
	dises: string;
	config: Config;
	
	displayCheckIndDiv = true;
	displayCheckDiv = false;
	displayCheckOutDiv = false;
	displayThumbDiv = false;
	
	displayDisplayDiv = true;
	
	//Error 
	displayErrorDiv = false;
	error = {message : ''};
	
	partyForm: FormGroup;
	// { id: 1, name:'Event1', hashtag: '#Event1' }
	
	post = [];
	//postForm: FormGroup;
	
	 headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	 
	 book = [
		  { id: 1, name: 'Core Java' },
		  { id: 2, name: 'Angular 2' } ];
		  
    constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private route: ActivatedRoute, private alertService: AlertService, public snackBar: MatSnackBar){
		this.config = new Config();
		this.createForm();
		//this.post = [ { dises: ''} ];
  }
	
	setAlert(type: string, message: string){
		this.alertService.alertType(type, message, false);
		//Success, Error, Info, Warning
	}
  
  createForm() {
    this.partyForm = this.fb.group({
		dises: '',
		id_event: '',
		code: ''
    });
	}
	
  ngOnInit() {
	this.route.params.subscribe(params => {
		this.idParty = +params['id']; // (+) converts string 'id' to a number
	});
	
	this.dises = localStorage.getItem('dises');
	
	this.getList();
	
	//Search in BDD last Id_Display created (recurrently)
	this.searchIdDisplayRecurrent();
  }
  
  openSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 1000 });
  }
  
  
  clickedRefresh(){
	this.searchIdDisplay(1);
  }
  
	extractData(res) { 
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		
		if(res.result == "ok"){
		
			//get list of display
			this.getList();
			
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
  
	extractDataStop(res) { 
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		
		if(res.result == "ok"){
			var id_display = res.id_display;
			
			let numberDisplays = Object.keys(this.listDisplays).length;
			
			// verify if event already exists on screen
			var alreadyExist=0;
			for (var j = 0; j < Object.keys(this.listDisplays).length; j++) {
				if(typeof this.listDisplays[j] !== "undefined"){
					if( this.listDisplays[j]['id_display'] == id_display){
						//delete this.listDisplays[j];
						this.listDisplays.splice(j,1);
					}
				}
			}
			
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
  
  
	extractDataList(res) { 
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		
		if(res.result == "ok"){
			
			let numberDisplays;
			
			let item;
			
			// for each event returned
			for (var i = 0; i < Object.keys(res).length; i++) {
				//console.log(res[i]);
				if(typeof res[i] !== "undefined"){
					//console.log(res[i]['name_event']);
					
					numberDisplays = Object.keys(this.listDisplays).length;
					
					// verify if event already exists on screen
					var alreadyExist=0;
					for (var j = 0; j < Object.keys(this.listDisplays).length; j++) {
						if(typeof this.listDisplays[j] !== "undefined"){
							if( this.listDisplays[j]['id_display'] == res[i]['id_display']){
								alreadyExist++;
							}
						}
					}
					if( alreadyExist == 0 ){
						this.listDisplays.push( {
							id_tmp: numberDisplays,
							id_display: res[i]['id_display'],
							id_event: res[i]['id_event']
						} );
					}
				}
			}
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
  
	clickedReturn() {
		this.router.navigate(['/histo']);
	}
	
	extractDataSearchId(res, force) { 
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		
		if(res.result == "ok"){
		
			let numberDisplays;
			
			let item;
			
			if(this.partyForm.value.code == '' || force == 1){
			
			// for each event returned
			for (var i = 0; i < Object.keys(res).length; i++) {
				//console.log(res[i]);
				if(typeof res[i] !== "undefined"){
					//console.log(res[i]['name_event']);
					
					this.partyForm.patchValue({code:res[i]['id_display']});
				}
			}
			
			}//if(this.partyForm.value.code == ''){
			
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
	
	getList() {
		this.post = [];
		this.post.push( { dises : localStorage.getItem('dises') } );
		this.post.push( { id_event : this.idParty } );
		
		this.http.post(this.config.httpAddress+"getManageDisplay.php", this.post, {headers: this.headers}).subscribe((data) => this.extractDataList(data));
		this.alertService.setProgressBar(1);
	}
	
	onSubmit() {
	//clicked();
	}
	
	doNothing() {
	}
	
	searchIdDisplayRecurrent(){
		this.searchIdDisplay(0);
		
		setTimeout(()=>{
			this.searchIdDisplay(0);
		},5000); /* rappel après 2 secondes = 2000 millisecondes */
	}
	
	searchIdDisplay(force){
		
		if(this.partyForm.value.code == '' || force == 1){
			this.post = [];
			this.post.push( { dises : localStorage.getItem('dises') } );
			this.post.push( { id_event : this.idParty } );
			this.post.push( { action : 'searchId' } );
			
			this.http.post(this.config.httpAddress+"getManageDisplay.php", this.post, {headers: this.headers}).subscribe((data) => this.extractDataSearchId(data, force));
			this.alertService.setProgressBar(1);
		}
		
	}
	
	clicked() {
		this.partyForm.value.dises = localStorage.getItem('dises');
		this.partyForm.value.id_event = this.idParty;
		
		this.http.post(this.config.httpAddress+"setManageDisplay.php", this.partyForm.value, {headers: this.headers}).subscribe((data) => this.extractData(data));
		this.alertService.setProgressBar(1);
	}
	
	clickedStop(id_display) {
		
		this.post = [];
		this.post.push( { dises : localStorage.getItem('dises') } );
		this.post.push( { id_event : this.idParty } );
		this.post.push( { id_display : id_display } );
		this.post.push( { action : 'stop' } );
		
		this.http.post(this.config.httpAddress+"setManageDisplay.php", this.post, {headers: this.headers}).subscribe((data) => this.extractDataStop(data));
		this.alertService.setProgressBar(1);
	}
}

