import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  /*template: `
    <p>
      index works!
    </p>
  `,*/
  styles: []
})
export class IndexComponent implements OnInit {
	
	//Error 
	displayErrorDiv = false;
	error = {message : ''};
	
  menuIsLogin = false;
	
  constructor() { }

  ngOnInit() {
	  //console.log("refreshMenu");
	if( localStorage.getItem('login') != '' && localStorage.getItem('login') != null ){
		this.menuIsLogin = true;
	}else{
		this.menuIsLogin = false;
	}
  }

}
