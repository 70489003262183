import { Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material';

import { AlertService } from '../_alert/alert.service';

import { Config } from '../data-model';

  
@Component({
  selector: 'app-party-form',
  templateUrl: './party-form.component.html',
  /*template: `
    <p>
      party-form works!
    </p>
  `,*/
  styles: []
})
export class PartyFormComponent implements OnInit, OnChanges {

	idParty: number;
  partyForm: FormGroup;
  hashtagReadonly = false;
	config: Config;
  
  //TODO SLECTED <mat-select [(value)]="selected" name="type" formControlName="type">
	
	//Error 
	displayErrorDiv = false;
	error = {message : ''};
  
  post = [];
    
  headers: HttpHeaders;

  constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private route: ActivatedRoute, private alertService: AlertService, public snackBar: MatSnackBar) {
	this.config = new Config();
    this.createForm();
	
	this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	
	if( localStorage.getItem('login') == '' || (localStorage.getItem('isGuest')=="1")){
		this.router.navigate(['/login']);
		this.setAlert("Warning", "loginToCreateEvent");
	}
  }
	
	setAlert(type: string, message: string){
		this.alertService.alertType(type, message, false);
		//Success, Error, Info, Warning
	}

  createForm() {
	
    this.partyForm = this.fb.group({
		dises: '',
		id: '',
      title: ['', Validators.required ],
      hashtag: '',
      startDate: '',
      endDate: '',
      isPublic: '',
      GPSlocate: '',
      gpsX: '',
      gpsY: '',
      accuracy: '',
      guestMode: '',
      prevalidate: '',
      type: ''
    });
  }

  onSubmit() {
	//clicked();
  }
  
  openSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 1000 });
  }

  clicked() {
	//console.log("max:"+localStorage.getItem('dises'));
	//console.log("tile:"+this.partyForm.value.title);
	
	this.partyForm.value.dises = localStorage.getItem('dises');
	//this.partyForm.append('dises', localStorage.getItem('dises'));
	
	this.http.post(this.config.httpAddress+"setPartyForm.php", this.partyForm.value, {headers: this.headers}).subscribe((data) => this.extractData(data));
	this.alertService.setProgressBar(1);
  }
  
  extractData(res) { 
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		//console.log(res.error);
		
		/*Errors:
		hashtagExist
		insertKO
		*/
		
		//console.log(res.dises);
		if(res.result == "ok"){
			let id=0;
			//this.user.dises = res.dises;
			//localStorage.setItem('dises', res.dises);
			//console.log('retOk:');
			
			//this.router.navigateByUrl('/send-media');
			//this.router.navigate(['/histo', idParty]);
			this.router.navigate(['/histo']);
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
	
  activeGeoloc() {
	if(this.partyForm.value.GPSlocate!=1){
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				//console.log("lati2:"+position.coords.latitude);
				//console.log("plus ou moins:"+position.coords.accuracy);
				
				//console.log("X1:"+this.partyForm.value.gpsX);
				
				//this.gpsX = position.coords.latitude;
				//this.partyForm.value.gpsX = position.coords.latitude;
				this.partyForm.patchValue({gpsX:position.coords.latitude});
				this.partyForm.patchValue({gpsY:position.coords.longitude});
				this.partyForm.patchValue({accuracy:position.coords.accuracy});
				/*this.gpsY = position.coords.longitude;
				this.partyForm.value.gpsY = position.coords.longitude;
				this.accuracy = position.coords.accuracy;
				this.partyForm.value.accuracy = position.coords.accuracy;
				*/
				
				//console.log("X2:"+this.partyForm.value.gpsX);
				
			 });
			
			
		} else {
			this.setAlert('Error', 'geolocNotSupported');
			//console.log("Geolocation is not supported by this browser.");
		}
	}
  }
  
  ngOnInit() {
	this.route.params.subscribe(params => {
		this.idParty = +params['id']; // (+) converts string 'id' to a number
	});
	
	this.loadDatas();
  }
  
  ngOnChanges() {
  }
  
  loadDatas() {
	if(this.idParty > 0 ){
		this.post = [];
		
		this.post.push( { 'dises' : localStorage.getItem('dises') } );
		this.post.push( { 'id' : this.idParty } );
		
		this.hashtagReadonly = false;
		
		this.http.post(this.config.httpAddress+"getPartyForm.php", this.post, {headers: this.headers}).subscribe((data) => this.extractDataForm(data));
		this.alertService.setProgressBar(1);
	}
  }
  
  cancel() { 
	window.history.back();
  }
  
  extractDataForm(res) { 
		this.alertService.setProgressBar(-1);
		//console.log(res);
		//console.log(res.result);
		//console.log(res.error);
		
		/*Errors:
		noIdFound, noDataIn, dataInNotArray, noAccess
		
"result": "ok",
"id_event": "'.htmlentities($row['id_event']).'",
"name_event": "'.htmlentities($row['name_event']).'",
"code_event": "'.htmlentities($row['code_event']).'",
"visibility_event": "'.htmlentities($row['visibility_event']).'",
"beginDate_event": "'.htmlentities($row['beginDate_event']).'",
"endDate_event": "'.htmlentities($row['endDate_event']).'",
"gps_event": "'.htmlentities($row['gps_event']).'",
"gpsX_event": "'.htmlentities($row['gpsX_event']).'",
"gpsY_event": "'.htmlentities($row['gpsY_event']).'",
"invitMode_event": "'.htmlentities($row['invitMode_event']).'",
"id_typeEvent": "'.htmlentities($row['id_typeEvent']).'",
"preValidation_event": "'.htmlentities($row['preValidation_event']).'"
		*/
		
		//console.log(res.dises);
		if(res.result == "ok"){
			let id=0;
			this.partyForm.patchValue({id: res.id_event});
			this.partyForm.patchValue({title: res.name_event});
			this.partyForm.patchValue({hashtag: res.code_event});
			this.partyForm.patchValue({startDate: new Date(res.beginDate_event)});
			this.partyForm.patchValue({endDate: new Date(res.endDate_event)});
			this.partyForm.patchValue({isPublic: (res.visibility_event=='1') });
			this.partyForm.patchValue({GPSlocate: (res.gps_event=='1') });
			this.partyForm.patchValue({gpsX: res.gpsX_event});
			this.partyForm.patchValue({gpsY: res.gpsY_event});
			this.partyForm.patchValue({accuracy: res.gpsAccuracy_event});
			//this.partyForm.patchValue({accuracy: res.name_event});
			this.partyForm.patchValue({guestMode: (res.invitMode_event=='1') });
			this.partyForm.patchValue({prevalidate: (res.preValidation_event=='1') });
			this.partyForm.patchValue({type: res.id_typeEvent});
			console.log('gpsX:'+ res.gpsX_event);
			//this.router.navigate(['/send-media', idParty]);
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
	
}
