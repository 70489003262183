import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Alert, AlertType } from '../_alert/alert';
import { AlertService } from '../_alert/alert.service';

@Component({
    //moduleId: module.id,
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent {
    alerts: Alert[] = [];
	displayProgressBar = false;
	stateProgressBar = 0;
	
	//public getTraduction = 'testAlert';
	
	@ViewChild('allCommentary', {static: false}) allCommentary : ElementRef;
	//@ViewChildren('allCommentary') allCommentaryChild : any;
	
    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.alertService.getAlert().subscribe((alert: Alert) => {
            if (!alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
			
			//Transform message code in commentary (for translation i18n)
			alert.message = this.getTraduction(alert.message);

            // add alert to array
            this.alerts.push(alert);
        });
		
        this.alertService.getProgressBar().subscribe((number: number) => {
            this.stateProgressBar += number;
			
			if(this.stateProgressBar > 0){
				this.displayProgressBar = true;
			}else{
				this.displayProgressBar = false;
			}
        });
    }
	
	//Transform message code in commentary (for translation i18n)
	public getTraduction(codeTraduction:string){
		
		let allSpan = this.allCommentary.nativeElement.children;
		for (var i = 0; i < allSpan.length; i++) {
			//console.dir(this.allCommentary.nativeElement.children[i].id);
			//console.dir(this.allCommentary.nativeElement.children[i].textContent);
			
			if(this.allCommentary.nativeElement.children[i].id==codeTraduction){
				codeTraduction = this.allCommentary.nativeElement.children[i].textContent;
			}
		}
		
		return codeTraduction;
	}

    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }

        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }
}