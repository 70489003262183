import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {Observable} from 'rxjs/Rx';
import { AlertService } from '../_alert/alert.service';

import { Config } from '../data-model';

@Component({
  selector: 'app-histo',
  templateUrl: './histo.component.html',
  /*template: `
    <p>
      histo works!
    </p>
  `,*/
  styleUrls: ['../app.component.css']
})
export class HistoComponent implements OnInit {

	displayGpsDiv = false;
	displayHashtagDiv = false;
	displayBackDiv = false;
	histoEvents = [];
	config: Config;
	
	
	//Error 
	displayErrorDiv = false;
	error = {message : ''};
	
	partyForm: FormGroup;
	// { id: 1, name:'Event1', hashtag: '#Event1' }
	
	post = [];
	//postForm: FormGroup;
	
	 headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	 
	 book = [
		  { id: 1, name: 'Core Java' },
		  { id: 2, name: 'Angular 2' } ];
		  
    constructor(private fb: FormBuilder, private http: HttpClient, private router: Router, private alertService: AlertService, public snackBar: MatSnackBar){
		this.config = new Config();
		this.createForm();
		
		//this.post = [ { dises: ''} ];
	}
	
	setAlert(type: string, message: string){
		this.alertService.alertType(type, message, false);
		//Success, Error, Info, Warning
	}

  createForm() {
    this.partyForm = this.fb.group({
		dises: '',
      hashtag: ''
    });
	}
	
  ngOnInit() {
	
	//this.post.dises = localStorage.getItem('dises');
	//this.post['dises'] = localStorage.getItem('dises');
	
	this.post = [];
	this.post.push( { dises : localStorage.getItem('dises') } );
	
	//console.log('retmax:'+localStorage.getItem('dises'));
	//console.log('retmax:'+this.post.dises);
	
	//this.postForm.value.dises = localStorage.getItem('dises');
	
	this.http.post(this.config.httpAddress+"getHisto.php", this.post, {headers: this.headers}).subscribe((data) => this.extractData(data));
	this.alertService.setProgressBar(1);
	
	/* work:
	this.http.get('https://backtest.djean.fr/seeschweiler.php').subscribe(data => {
      console.log("Histo: User Login: " + data.login);
    });
	this.searchNearlyEvent(); 
	*/
	
	/*
	if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
			console.log("lati:"+position.coords.latitude);
			console.log("plus ou moins:"+position.coords.accuracy);
		 });
		
		
    } else {
        console.log("Geolocation is not supported by this browser.");
    }
	*/
	
	
  }
  
  openSnackBar(message: string) {
    this.snackBar.open(message, '', { duration: 1000 });
  }
  
  
	extractData(res) {
		this.alertService.setProgressBar(-1);
		
		this.displayGpsDiv = false;
		this.displayHashtagDiv = false;
		this.displayBackDiv = false;
		
		//console.log(res);
		//console.log(res.result);
		//console.log(res.login);
		//console.log(res.dises);
		if(res.result == "ok"){
			//let id=0;
			//this.user.dises = res.dises;
			//localStorage.setItem('dises', res.dises);
			
			//var jsonData = JSON.parse(res);
			//console.log(res.length);
			var count = Object.keys(res).length;
			//console.log(count);
			
			// for each event returned
			for (var i = 0; i < Object.keys(res).length; i++) {
				//console.log(res[i]);
				if(typeof res[i] !== "undefined"){
					//console.log(res[i]['name_event']);
					
					// verify if event already exists on screen
					var alreadyExist=0;
					for (var j = 0; j < Object.keys(this.histoEvents).length; j++) {
						if(typeof this.histoEvents[j] !== "undefined"){
							if( this.histoEvents[j]['id_event'] == res[i]['id_event']){
								alreadyExist++;
							}
						}
					}
					if( alreadyExist == 0 ){
						this.histoEvents.unshift( {
							id_event: res[i]['id_event'],
							name_event: res[i]['name_event'],
							nameLetter_event: res[i]['name_event'].substring(0,1).toUpperCase(),
							code_event: res[i]['code_event'],
							visibility_event: (res[i]['visibility_event']==1),
							invitMode_event: (res[i]['invitMode_event']==1),
							isManager: res[i]['isManager']
						} );
					}else{
						//console.log(res[i]['name_event']+" already Exist");
					}
					
				}
			}
			/*for (var i = 0; i < jsonData.counters.length; i++) {
				var counter = jsonData.counters[i];
				console.log(counter.counter_name);
			}*/
			
		}else{
			// Actions if return is not OK
			let actionDone : boolean = false;
			if(typeof res.error !== "undefined"){
				if(res.error == "somethingSpecialToDoHere"){
					actionDone = true; //And do not the standard actions
				}
			}
			//If there is no special action to do, we do the generic action (Logout, show the alert, ...)
			if(!actionDone){
				this.alertService.returnKo(res);
			}
		}
	}
  
	clickedFind() {
		this.displayBackDiv = true;
		this.displayGpsDiv = true;
	}
	
	onSubmit() {
	//clicked();
	}
  
	clickedSearch() {
		this.partyForm.value.dises = localStorage.getItem('dises');
		
		//this.http.post(config.httpAddress+"setPartyForm.php", this.partyForm.value, {headers: this.headers}).subscribe((data) => this.extractData(data));
		this.http.post(this.config.httpAddress+"getHisto.php", this.partyForm.value, {headers: this.headers}).subscribe((data) => this.extractData(data));
		this.alertService.setProgressBar(1);
	}
  
	saveEventAssociation(id_event) {
		this.post = [];
		this.post.push( { dises : localStorage.getItem('dises') } );
		this.post.push( { 'id_event' : id_event } );
		
		this.http.post(this.config.httpAddress+"setEventAssociation.php", this.post, {headers: this.headers}).subscribe();
	}
	
	doNothing() {
	}

	clickedGpsYes() {
		
		if (navigator.geolocation) {
			this.alertService.setProgressBar(1);
			navigator.geolocation.getCurrentPosition(position => {
				console.log("lati2:"+position.coords.latitude.toString());
				console.log("plus ou moins:"+position.coords.accuracy.toString());
				
				// Save GPS position
				localStorage.setItem('gpsX', position.coords.latitude.toString());
				localStorage.setItem('gpsY', position.coords.longitude.toString());
				localStorage.setItem('accuracy', position.coords.accuracy.toString());
				
				// Creation data to post
				this.post = [];
				this.post.push( { dises : localStorage.getItem('dises') } );
				this.post.push( { gpsX : localStorage.getItem('gpsX') } );
				this.post.push( { gpsY : localStorage.getItem('gpsY') } );
				this.post.push( { accuracy : localStorage.getItem('accuracy') } );
				
				// Find some party arround
				this.http.post(this.config.httpAddress+"getHisto.php", this.post, {headers: this.headers}).subscribe((data) => this.extractData(data));
				//this.alertService.setProgressBar(1);
				
			 }, function(error){
			this.alertService.setProgressBar(-1);
             alert(error.message);
        }, {
             enableHighAccuracy: true
                  ,timeout : 30000
        });
			
			
		} else {
			console.log("Geolocation is not supported by this browser.");
			alert("Geolocation is not supported by this browser.");
			this.clickedGpsNo();
		}
	}

	clickedGpsNo() {
		this.displayGpsDiv = false;
		this.displayHashtagDiv = true;
	}
  
	clickedCreate() {
	  this.router.navigate(['/party-form', -1]);
	  }
  
	clickedEvent(id) {
		let eventName: string = "";
		let eventCode: string = "";
		//Find Event Name
		for (var j = 0; j < Object.keys(this.histoEvents).length; j++) {
			if(typeof this.histoEvents[j] !== "undefined"){
				if( this.histoEvents[j]['id_event'] == id){
					eventName = this.histoEvents[j]['name_event'];
					eventCode = this.histoEvents[j]['code_event'];
				}
			}
		}
		localStorage.setItem('eventName', eventName);
		localStorage.setItem('eventCode', eventCode);
		localStorage.setItem('event', id);
		
		//Update association user/event
		this.saveEventAssociation(id);
		
		this.router.navigate(['/send-media', id]);
	}
  
	clickedDisplay(id) {
	  this.router.navigate(['/manage-display', id]);
	  }
  
	clickedManage(id) {
	  this.router.navigate(['/manage-form', id]);
	  }
  
	clickedModify(id) {
	  this.router.navigate(['/party-form', id]);
	  }

}
